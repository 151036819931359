import { Col, Row } from "antd";
import ProductAndService from "../ProductAndService";

export default function ProductsAndServicesGrid({ items, colWidth }) {
  return (
    <Row gutter={40}>
      {items.map((item) => (
        <Col key={item.id} span={colWidth}>
          <ProductAndService {...item} />
        </Col>
      ))}
    </Row>
  );
}
