import Image from "next/image";
import Typography from "../../../components/Typography";
import { ImageContainer } from "../styled";
import Link from "next/link";

export default function ProductAndService({ image, title, content }) {
  return (
    <Link passHref href={image.href}>
      <a>
        {image.src && (
          <ImageContainer>
            <Image alt="Product" {...image} />
          </ImageContainer>
        )}
        <Typography
          textAlign="center"
          color="darkGray2"
          // fontWeight="bold"
          fontSize="1.6rem"
          fontFamily="Roboto Bold"
          style={{ letterSpacing: "0.1rem" }}
        >
          {title}
        </Typography>
        <Typography fontFamily="Roboto Medium" textAlign="center">
          {content}
        </Typography>
      </a>
    </Link >
  );
}
