import useMobile from "../../../hooks/useMobile";
import ProductsAndServices from "../../ProductsAndServices";
import pageData from "../data.json";

export default function ProductsAndServicesSection() {
  const isMobile = useMobile();
  return (
    <ProductsAndServices
      items={pageData["products-and-services"]}
      colWidth={24 / 4}
      variant={isMobile ? "slider" : "grid"}
    />
  );
}
